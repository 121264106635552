@import './../../../../scss/theme-bootstrap';

.product-shade-picker {
  // hex-width is the actual swatch and not including its border
  $shade-border-width: 1px;
  $space-between-border: 3px;
  $hex-width: 30px;
  $shade-width: $hex-width + ($space-between-border * 2);

  $hex-width--mobile: 35px;
  $shade-width--mobile: $hex-width--mobile + ($space-between-border * 2);

  $hex-per-row: 9;
  overflow: hidden;
  @include breakpoint($large-up) {
    border: 0;
  }
  @include pie-clearfix;
  &__shade-count {
    display: none;
    &.acitve {
      display: block;
    }
  }
  &__smoosh {
    display: inline-block;
    margin: 0 auto 20px;
    &-img {
      display: block;
    }
    @include breakpoint($large-up) {
      float: right;
      margin: 0 0 0 20px;
    }
  }
  &__shade-overflow-link {
    cursor: pointer;
    font-size: 30px;
    &:active,
    &:hover,
    & {
      text-decoration: none;
    }
    display: none;
    .show-less {
      display: none;
    }
  }
  &.show-shade-overflow &__shade-overflow-link {
    .show-less {
      display: inline;
    }
    .show-more {
      display: none;
    }
  }
  &__label {
    display: none;
    font-size: 12px;
    margin: 0 auto 5px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  &__shades {
    @include pie-clearfix;
    white-space: nowrap;
    width: 100%;
    overflow-x: hidden;
    @include breakpoint($large-up) {
      // Max width of the number of hexes we want per row
      // times the hex width, plus 8 margin offset per hex
      max-width: ($shade-width * $hex-per-row + (8 * $hex-per-row));
      float: left;
      width: auto;
    }
    &.slick-initialized {
      float: none;
      margin: 0 auto;
    }
  }
  &__shade-item {
    display: inline-block;
    margin: 0 2px;
    opacity: 1;
    transition: opacity 0.7s ease;
    .active {
      .product-shade-picker__shade__inner {
        border: $shade-border-width solid $color-black;
      }
    }
    &.shade-hidden {
      display: none;
      width: 0;
      height: 0;
      margin: 0;
      overflow: hidden;
    }
  }
  &__shade {
    cursor: pointer;
    display: inline-block;
    padding: 5px 0;
    @include breakpoint($large-up) {
      padding: 0;
    }
    &__outer {
      border: 0.5 solid transparent;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: $shade-width--mobile;
      height: $shade-width--mobile;
      @include breakpoint($large-up) {
        width: $shade-width;
        height: $shade-width;
      }
    }
    &.active &__outer {
      border: $shade-border-width solid $color-pink-accent;
    }
    &:hover &__outer {
      @include breakpoint($large-up) {
        border: $shade-border-width solid $color-pink-accent;
      }
    }
    // inner is used to make the actual color/swatch element a circle
    &__inner {
      border-radius: 50%;
      width: $hex-width--mobile;
      height: $hex-width--mobile;
      overflow: hidden;
      @include breakpoint($large-up) {
        width: $hex-width;
        height: $hex-width;
      }
      .cross-out-shade & {
        position: relative;
      }
    }
    // color/swatch element
    &__color {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-size: cover;
      &::after {
        .cross-out-shade .product-shade-picker__shade__inner & {
          content: '';
          background-color: $color-white;
          position: absolute;
          height: 2px;
          width: 120%;
          top: calc(50% - 1px);
          #{$ldirection}: -10%;
          transform: rotate(-48deg);
          z-index: 0;
        }
      }
    }
  }
  &__shade_families {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 5px;
    align-items: center;
    text-align: left;
    margin-left: 15px;
    margin-bottom: 10px;
    @include breakpoint($large-up) {
      margin-left: 0;
    }
  }
  &__shade_family {
    color: $color-black;
    cursor: pointer;
    font-family: $font--proxima-nova;
    font-size: 14px;
    line-height: 19px;
    margin-right: 15px;
    padding-bottom: 5px;
    text-transform: uppercase;
    &.active {
      font-weight: bold;
      text-decoration: underline;
      text-decoration-color: $color-light-gray;
    }
    &--has-image {
      span {
        display: none;
      }
      img {
        display: inline-block;
        max-height: 50px;
        max-width: none;
      }
    }
  }
}

.product-shade-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.shade-prefix {
  font-weight: bold;
}

.product-brief__shade-picker {
  position: relative;
  > .slick-arrow {
    background-position: center center;
    background-size: 250%;
    background-color: transparent;
    backdrop-filter: none;
    margin-top: 5px;
    filter: saturate(0) brightness(0);
    width: 8px;
  }
}

.product-full__shadepicker-carousel {
  position: relative;
  > .slick-arrow {
    background-position: center center;
    margin-top: -1px;
    width: 50px;
    height: 50px;
    &.slick-next {
      right: 0;
    }
    &.slick-prev {
      left: 0;
    }
    @include breakpoint($large-up) {
      margin-top: -9px;
    }
  }
  &.no-shade-families {
    > .slick-arrow {
      margin-top: -18px;
    }
  }
  .slick-list,
  .slick-track {
    width: 100%;
  }
  // CLS style for mobile, only show first 5 items ( match slider config )
  .product-shade-picker__shade-item:nth-of-type(5)
    ~ .product-shade-picker__shade-item:not(.shade-hidden) {
    display: none;
    @include breakpoint($large-up) {
      display: block;
    }
  }
  .slick-initialized {
    .product-shade-picker__shade-item:nth-of-type(5)
      ~ .product-shade-picker__shade-item:not(.shade-hidden) {
      display: block;
    }
  }
  .product-shade-picker {
    &__shades {
      padding: 0 50px;
      justify-content: space-between;
      @include breakpoint($large-up) {
        justify-content: start;
        padding: 0;
      }
    }
  }
}
